<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useStore } from '@/stores/store';
import type { UnreadNotification, UnreadNotifications } from '~/interfaces/notifications/main';

const store = useStore();
const router = useRouter();
const route = useRoute();
const opportunitiesStore = useOpportunitiesStore();
const usersStore = useUsersStore();
const notificationsStore = useNotificationsStore();
const { opportunity } = storeToRefs(opportunitiesStore);
const { authUserData } = storeToRefs(usersStore);

const unreadNotifications = ref<UnreadNotifications>();
const notifBellEl = ref<DOMTokenList>(null);
const menu = ref<DOMTokenList>(null);

const userMenuOptions = ref([
  {
    label: 'Paramètres',
    items: [
      {
        label: 'Notifications',
        icon: 'pi pi-bell',
        command: () => {
          router.push('/settings/notifications');
        },
      },
    ],
  },
]);

const toggleNotificationsOverlay = (event) => {
  notifBellEl.value.toggle(event);
};

const userTrigram = computed(() => {
  return authUserData.value?.username.slice(0, 2).toUpperCase();
});

const fetchNotifications = async (notifIssuer = null) => {
  if (await notifIssuer && unreadNotifications.value) { // optimistic update
    unreadNotifications.value.data.find((notif: UnreadNotification) => notif.id === notifIssuer.id).readAt = notifIssuer.readAt;
  }

  unreadNotifications.value = await notificationsStore.getNotifications(true, 5);
};

const toggleSettingOverlay = (event) => {
  menu.value.toggle(event);
};

onMounted(async () => {
  await fetchNotifications();
});
</script>

<template>
  <div class="flex justify-between items-center px-2 bg-surface-0 dark:bg-surface-950 relative lg:static border-b border-surface">
    <div class="flex top-bar-left">
      <a
        v-ripple
        v-styleclass="{ selector: '#app-sidebar-5', enterClass: 'hidden', enterActiveClass: 'fadeinleft', leaveToClass: 'hidden', leaveActiveClass: 'fadeoutleft', hideOnOutsideClick: true }"
        class="cursor-pointer block lg:hidden text-surface-700 dark:text-surface-100 mr-4 mt-1 p-ripple"
      >
        <i class="pi pi-bars text-4xl" />
      </a>
      <!-- Commerce Buttons -->
      <div v-if="['commerce-sites', 'commerce-contacts'].includes(route.name) && !store.isMobile">
        <NuxtLink :to="{ name: 'commerce-sites' }">
          <Button
            :class="['border-round-sm', route.name === 'commerce-sites' ? 'text-white custom-fv-button' : '']"
            :outlined="!route.name === 'commerce-sites'"
            :severity="route.name === 'commerce-sites' ? 'warning' : 'secondary'"
            icon="pi pi-building"
            label="SITES"
          />
        </NuxtLink>
        <NuxtLink :to="{ name: 'commerce-contacts' }">
          <Button
            :class="['mx-2 border-round-sm', route.name === 'commerce-contacts' ? 'text-white custom-fv-button' : '']"
            :outlined="!route.name === 'commerce-contacts'"
            :severity="route.name === 'commerce-contacts' ? 'warning' : 'secondary'"
            icon="pi pi-users"
            label="CONTACTS"
          />
        </NuxtLink>
      </div>
      <!-- Opportunities Buttons -->
      <div v-if="route.name.includes('opportunities-opportunityHashId') && !store.isMobile && opportunity">
        <!-- <NuxtLink:to="{ name: 'opportunities-opportunityHashId-preview', params: { id: opportunity.hashId } }"> -->
        <Button
          :class="['m-2 p-2 rounded w-40 h-8 text-sm font-bold', route.params.opportunityHashId === opportunity.hashId ? 'text-white custom-fv-button' : '']"
          :label="'RAC #' + opportunity.reqId"
          :outlined="route.params.opportunityHashId !== opportunity.hashId"
          :severity="route.params.opportunityHashId === opportunity.hashId ? 'warning' : 'secondary'"
          icon="pi pi-star"
        />
        <!-- </NuxtLink> -->
        <!-- TODO loop through related opportunities -->
      </div>
      <span class="p-input-icon-left self-center hidden lg:block">
        <i class="pi pi-search text-xl align-middle" />
        <InputText
          class="border-0 w-40 sm:w-80 text-xs ml-2"
          disabled
          placeholder="Numéro dossier, Nom client, Numéro de téléphone..."
          type="text"
        />
      </span>
    </div>
    <div class="ml-auto mr-4">
      <div class="bg-orange-500 p-1 text-sm rounded-md text-white">
        {{ useRuntimeConfig().public.APP_VERSION }}
      </div>
    </div>
    <a
      v-ripple
      v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'fadein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }"
      class="cursor-pointer block lg:hidden text-surface-700 dark:text-surface-100 p-ripple"
    >
      <i class="pi pi-ellipsis-v text-2xl" />
    </a>
    <ul
      class="list-none p-0 m-0 hidden lg:flex lg:align-items-center select-none lg:flex-row
                surface-section border-1 lg:border-none surface-border right-0 top-100 z-1 shadow-2 lg:shadow-none absolute lg:static"
    >
      <li class="disabled">
        <a
          v-ripple
          class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round
                        transition-duration-150 transition-colors p-ripple"
        >
          <i class="pi pi-phone text-base text-green-500 lg:text-2xl mr-2 lg:mr-0" />
          <span class="block lg:hidden font-medium">Phone</span>
        </a>
      </li>
      <li class="disabled">
        <a
          v-ripple
          class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round
                        transition-duration-150 transition-colors p-ripple"
        >
          <i class="pi pi-inbox text-base lg:text-2xl mr-2 lg:mr-0" />
          <span class="block lg:hidden font-medium">Inbox</span>
        </a>
      </li>
      <li class="disabled">
        <a
          class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round
                        transition-duration-150 transition-colors"
          @click="toggleNotificationsOverlay"
        >
          <i
            v-if="unreadNotifications?.meta?.totalItems"
            v-badge.danger="unreadNotifications?.meta?.totalItems"
            class="pi pi-bell cursor-pointer text-base lg:text-2xl mr-2 lg:mr-0"
          />
          <i
            v-else
            class="pi pi-bell cursor-pointer text-base lg:text-2xl mr-2 lg:mr-0"
          />
          <span class="block lg:hidden font-medium">Notifications</span>
        </a>

        <Popover
          v-if="unreadNotifications"
          ref="notifBellEl"
        >
          <div style="width: min(90vw, 400px);">
            <div class="flex justify-between items-center mt-1 mb-4">
              <div class="flex items-center gap-1">
                <span class="text-2xl font-semibold">Notifications non lues</span>
              </div>
              <span>{{ unreadNotifications.meta.totalItems }} non lues</span>
            </div>

            <div
              v-if="unreadNotifications.data.length"
              class="flex flex-col"
            >
              <NotificationsCard
                v-for="notification in unreadNotifications.data"
                :key="notification.id"
                :notification="notification"
                @refresh="fetchNotifications(notification)"
              />
            </div>

            <div
              v-else
              class="text-center py-6"
            >
              Aucune notification non lue
            </div>

            <NuxtLink
              class="block text-center pt-4"
              to="/notifications"
            >
              <Button
                outlined
                severity="secondary"
              >
                Voir toutes les notifications
              </Button>
            </NuxtLink>
          </div>
        </Popover>
      </li>
      <li class="border-t border-surface lg:border-t-0 disabled">
        <a
          v-ripple
          class="flex p-3 lg:px-3 lg:py-2 align-items-center hover:surface-100 font-medium border-round
                        transition-duration-150 transition-colors p-ripple"
          @click="toggleSettingOverlay"
        >
          <Avatar
            :label="userTrigram"
            class="p-4 cursor-pointer"
            shape="circle"
          />
          <Menu
            ref="menu"
            :model="userMenuOptions"
            :popup="true"
          />
          <div class="block lg:hidden">
            <div class="text-surface-900 dark:text-surface-0 font-medium">Alban Tor</div>
            <span class="text-surface-600 dark:text-surface-200 font-medium text-sm">Marketing Specialist</span>
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>
